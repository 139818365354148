import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { adapterFactory } from "angular-calendar/date-adapters/moment";
import * as moment from "moment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LogoutComponent } from "./logout.component";
import { SharedModule } from "./modulos/shared/shared.module";
import { AuthGuard } from "./auth-guard.service";
import { AuthLogin } from "./auth-login.service";
import { ClienteService } from "./modulos/clientes/cliente.service";
import { CredenciadoService } from "./modulos/credenciados/services/credenciado.service";
import { SplashScreenService } from "./splash-screen.service";
import { UserService } from "./modulos/shared/services/user.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import localePt from "@angular/common/locales/pt";
import "moment/locale/pt-br";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BaseComponent } from "./template/base/base.component";
import { AppMenuComponent } from "./template/menu/menu.component";
import { AppHeaderComponent } from "./template/header/header.component";
import { AppFooterComponent } from "./template/footer/footer.component";
import { SistemaService } from "./modulos/shared/services/sistema.service";

moment.locale("pt-br");

registerLocaleData(localePt);

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    BaseComponent,
    AppHeaderComponent,
    AppMenuComponent,
    AppFooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    FormsModule,
    SharedModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "pt" },
    AuthGuard,
    AuthLogin,
    SistemaService,

    UserService,
    ClienteService,
    CredenciadoService,

    SplashScreenService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
