import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

export const USE_NEW_API_DOMAIN_HEADER = "X-Use-New-Api-Domain";

export class ApiDomainService {
  private static readonly OLD_API_DOMAIN_TOKEN_HEADER = "X-Auth-Token-Update";
  private static readonly NEW_API_DOMAIN_TOKEN_HEADER = "Authorization";

  private useNewApiDomain: boolean;

  constructor(headers: HttpHeaders) {
    this.useNewApiDomain = this.isNewApiDomain(headers);
  }

  getApiDomain(): string {
    if (this.useNewApiDomain) {
      return environment.newServerUrl;
    }
    return environment.serverUrl;
  }

  private isNewApiDomain(headers: HttpHeaders): boolean {
    const headerValue = headers.get(USE_NEW_API_DOMAIN_HEADER)?.toLowerCase();
    return headerValue === "true";
  }

  getTokenHeader(): string {
    if (this.useNewApiDomain) {
      return ApiDomainService.NEW_API_DOMAIN_TOKEN_HEADER;
    }
    return ApiDomainService.OLD_API_DOMAIN_TOKEN_HEADER;
  }

  getTokenFromStorage(): string {
    let token = localStorage.getItem("token");
    if (this.useNewApiDomain) {
      token = `Bearer ${token}`;
    }
    return token;
  }
}
