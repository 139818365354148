export function ValidationErrorHandler(validationError: {
  message: string;
  errors: Record<string, string[]>;
}): string {
  let { message, errors } = validationError;

  if (message !== "Validation error") {
    return message;
  }

  let errorMessage = "<dl class='text-start'>";
  Object.entries(errors).forEach(([errorField, errorReasons]) => {
    errorMessage += `<dt>${errorField}:</dt>`;
    errorReasons.forEach((errorReason) => {
      errorMessage += `<dd class="ms-4">${errorReason}</dd>`;
    });
  });

  errorMessage += "</dl>";

  return errorMessage;
}
